const initialState = {
  html1: [
    'Un element HTML este format din continut, taguri de inchidere si deschidere.',
    'Un document HTML este format din elementele head si body, imbracate in elementul html.',
    'Un element se considera pozitionat atunci cand valoarea proprietatii position, diferta de "static".',
    'Exemple de elemente de tip block sunt: <div>, <section>, <form>, <ul>, <ol>, <table>, <article>, <nav>, <h1>-<h6>.',
    'Exemple de elemente de tip inline sunt: <a>, <img>, <span>, <button>.',
    'POST si GET sunt metode (sau verbe http) prin care se transmite informatia din formulare catre URL-ul specificat. ',
    'Input-ul checkbox genereaza o casuta de bifat si functioneaza bine cu elementul label, fiind legate prin atributul for. ',
    'Input-ul radio este similar cu checkbox, dar atunci cand ele formeaza un radio group, doar unul poate fi bifat.',
    'Elementul select genereaza o lista dropdown cu valori (elemente de tip option). ',
    'Textarea este un element de formular care permite introducerea unei cantitati de text pe mai multe randuri.',
    '<header> este un element semantic de tip block ce delimiteaza si poate contine informatie cu rol de antent',
    '<footer> este un element semantic de tip block ce marcheaza subsolul sectiunii parinte (elemente legate de continutul principal dar nu esentiale)',
    '<aside> este un element semantic de tip block ce delimiteaza continut care este legat de continutul principal in mod tangential (exemplu: linkuri spre articole similare).',
    '<main> este un element semantic de tip block ce delimiteaza continutul principal al unui document si poate exista o singura data per document',
    '<nav> este un element semantic de tip block folosit pentru delimitarea navigatiei unui document (meniuri, grupuri de butoane etc.)',
    'Modurile prin care se poate adauga cod CSS in HTML: fisier extern (elementul <link>); elementul <style> (in mod tipic se adauga in elementul head) si inline (ca atribut - style- al oricarui element).',
    'In CSS, selectarea se face prin atribute speciale adaugate in markup, respectiv id si class.',
    'Un selector CSS poate fi compus atat din identificatori de clasa, atribut, cat si de id.',
    'Box model este reprezentarea tipica a elementelor dintr-un document si are 4 zone revelante: continut, padding, bordura si margini. ',
    'Unitatile de masura pentru width si height, in CSS, sunt: px, em, %, vh, vw. ',
    'Proprietatea padding controleaza spatiul ramas intre continut si bordura elementului.',
    'Proprietatea border manipuleaza culoarea, grosimea si stilul bordurii elementului. Border radius manipuleaza raza colturilor bordurii.',
    'Proprietatea margin manipuleaza zonele din afara bordurii elementului. ',
    'box-sizing seteaza box modelul elementului: content-box (sistemul clasic - paddingul se aduna la cutie) si border-box (sistemul nou - contentul este redimensionat in functie de dimensiunile specificate pe cutie).',
    'Proprietatea position manipuleaza modul in care se pozitioneaza elementul in flowul documentului.',
    'Pozitionarea implicita este "static". Cand valoarea difera de static, elementul se va numi “pozitionat”.',
    'Relative: elementul se va rupe din document flow, dar se va pozitiona relativ fata de pozitia pe care ar avea-o in mod normal. ',
    'Absolute: elementul se va rupe complet din flow si se va pozitiona absolut fata de primul stramos pozitionat sau daca nu intalneste unul, fata de document.',
    'Fixed: elementul se va rupe din flow si va ramane pe ecran indiferent de pozitia scrollbarului. ',
    'Stacking context este un model de ordonare pe axa "z" a cutiilor care s-ar putea suprapune din diverse motive.',
    'Valorile posibile pentru proprietatea display: block, inline-block, flex, inline-flex, grid, inline-grid.',
    'Proprietatea float scoate elementul din flowul normal al paginii si are ca valori posibile: left, right si none.',
    'Transformarile CSS sunt modificari vizuale ale elementului randat in document, create prin proprietatea speciala transform.',
    'Combinatorii sunt simboluri speciale (>, +, ~, inclusiv spatiul) folosite pentru a crea selectori avansati, facand referinta la relatia dintre elemente (descendenta sau adiacenta)',
    'Specificitatea este un mecanism prin care browserul decide declaratiile carui selector sunt aplicate pe un element in functie de cat de specific este selectorul respectiv.',
    'Mostenirea: anumite proprietati setate pe un element parinte, se vor aplica si pe descendentii directi si indirecti ai acestuia (daca nu exista declaratii venite din reguli cu selectori mai specifici pe acestia).',
    'CDN (Content Delivery Network): server specializat care serveste resurse statice (imagini, video, text). Pot fi referentiate si ca Edge servers.',
    'Atributul target cu valoarea _blank pe o ancora va instrui browserul sa deschida acea pagina intr-un tab nou.',
    'Un element ul poate contine doar elemente li ca si descendenti directi',
    'Un element select poate contine doar elemente option sau optgroup ca descendenti directi',
    'Metoda POST va trimite informatie in corpul requestului iar GET va adauga informatia la URL',
    'URL inseamna Uniform Resource Locator',
  ],
  html2: [
    'Alinierea intr-un grid de CSS se face intre liniile gridului si nu bazat pe randuri si coloane.',
    'Un PWA (Progressive Web App) este un website care arata si se comporta ca o aplicatie de mobil.',
    'npm este un package manager pentru node.js, iar pachetele se instaleaza folosind comanda npm install nume_pachet',
    '@keyframes este un at-rule care specifica cadrele prin care trebuie sa treaca o animatie de CSS.',
    'Intr-un container de tip flex, descendentii directi ai acestuia vor deveni flex items si vor putea fi pozitionati printr-un mecanism mai avansat decat float sau position.',
    'Elementele unui flex container se vor alinia pe axa principala (main) si axa transversala (cross), nu se numesc x si y pentru ca flex container se poate roti.',
    'Pe axa principala a unui container flex se foloseste proprietatea justify-content, iar pe axa transversala se foloseste proprietatea align-items.',
    'Proprietatea align-self aliniaza individual un anume flex item pe axa transversala a cutiei flex. ',
    'flex-shrink / flex-grow se aplica pe flex items si specifica coeficientul cu care acestea trebuie sa se micsoreze / mareasca. ',
    'align-content aliniaza in mod similar cu align-items liniile unui container flex cu wrap',
    'CDN (Content Delivery Network) este un o retea de servere menite sa serveasca informatie pe web, cat mai apropiate de locatia fizica a utilizatorului.',
  ],
  js: [
    'Tipurile de date primitive in JavaScript sunt String, Number si Boolean.',
    'JavaScript nu are decat tipul Number pentru a reprezenta orice fel numar.',
    'In JavaScript obiectele se transmit prin referinta si nu prin valoare.',
    'Exista trei moduri prin care se poate adauga cod JavaScript in HTML: fisier extern, intern, cu elementul <script> si inline, cu event handlers.',
    'Local scope: variabilele si functiile declarate in interiorul unei functii pot fi accesate doar in interiorul ei.',
    'Daca o variabila este declarata in contextul global, aceasta poate fi accesata de oriunde din aplicatie.',
    'In contextul programarii clasice,"this" este folosit pentru a accesa instanta curenta.',
    'IIFE / SIAF (Immediately Invoked Function Expression) - o functie care ruleaza imediat ce este definita, de obicei anonima (nu are denumire).',
    'Operatorul == reprezinta egalitatea toleranta (type coercion), in timp ce === verifica atat egalitatea cat si tipul de date folosit (valorile trebuie sa fie de acelasi tip).  ',
    'Scope-ul (contextul) in JS determina accesibilitatea variabilelor, obiectelor si functiilor intr-o anumita parte a codului - global scope si local scope.',
    'Rezultatul operatiunii cu operatori booleni este tot o valoare booleana.',
    'Obiectele pot fi literale (folosite la structuri de date sau la configurari) sau instante (returnate de constructorul unei clase).',
    'Hardcodare - a atribui o valoare unei variabile direct din cod, fara a fi solicitata de la un user, scoasa dintr-o baza de date sau preluata dintr-un API.',
    'O expresie in JavaScript este orice combinatie de variabile, operatori, alte expresii care evalueaza la o singura valoare.',
    'Blocul if este compus dintr-un bloc logic in care se folosesc una sau mai multe conditii.',
    'Bucla for este un statement care repeta instructiuni, in functie de o variabila care se incrementeaza la fiecare iteratie.',
    'Array.forEach() este o metoda iterativa care ruleaza pe arrayuri, pentru fiecare element din array executa o functie callback.',
    'In cazul forEach(), keywordurile continue si break nu functioneaza.',
    'Argumentele callbackului metodei Array.forEach() sunt elementul curent, indexu curent si o referinta la arrayul initial.',
    'Object.keys() este o metoda care returneaza un array cu numele cheilor unui obiect.',
    'Functiile sunt obiecte reprezentate prin blocuri de cod unitare cat mai specifice ce pot fi refolosite si parametrizate.',
    'Functiile pot returna o valoare prin keywordul return care de asemenea opreste complet executia acestora.',
    'Atunci cand functiile sunt proprietati ale obiectelor, ele se numesc metode.',
    "Bracket notation este metoda de a accesa elementele unui array dupa index (arr[2]) sau proprietatile unui obiect dupa numele lor reprezentat ca string (myObj['myProp'])",
    'O functie anonima poate fi executata, dar nu are un nume, ea sa poate folosi pe post de callback pentru diferite metode.',
    'O functie overloaded poate rula alte operatiuni in functie de numarul (sau tipul) argumentelor pe care le primeste.',
    'BOM (Browser Object Model) este o colectie ierarhica de obiecte care contin metode si proprietati asociate cu browserul in sine',
    'Metodele .querySelector si .querySelectorAll primesc un selector identic cu cei din CSS.',
    'Programarea clasica este programarea bazata pe clase (se considera class based)',
    'Programarea orientata pe obiecte (OOP - Object Oriented Programming) este centrata pe notiuna de clase si instante (in JavaScript sunt obiecte simple).',
    'Cele 4 principii ale OOP sunt: encapsulation, inheritance, polymorphism si abstraction.',
    'JavaScript este un limbaj bazat pe prototipuri (fiecare obiect are un prototip).',
    'Prototype chain poate fi vazut ca mecanismul "real" de mostenire din JavaScript, fiind prototype based, fiecare obiect are o proprietate __proto__ prin care poate mosteni de la alte obiecte mai generice.',
    'Method chaining este o tehnica in programarea cu obiecte prin care fiecare metoda a unui obiect sau a unei instante returneaza ori obiectul this, ori un alt obiect.',
    'DOM (Document Object Model) permite JavaScript sa interactioneze cu elementele documentului incarcat in browser.',
    'Spread operator (...) copiaza (shallow copy) elementele dintr-un array sau obiect total sau partial, intr-un alt array sau obiect.',
    'Variable look-up este procesul prin care parserul verifica daca in contextul local (curent) are o anumita variabila accesibila, iar daca nu urca in sus in contexte, pana ajunge in cel global.Obiectele in JavaScript pot fi definite ca obiecte literale, specificand proprietatile prin cheie si valoare; sau pot fi instantiate din anumite clase.',
    'JavaScript fiind orientat pe prototipuri, isi bazeaza mosternirea pe conceptul de prototype chain, in care fiecare are obiect are o proprietate numita prototype (__proto__) in care exista o referinta la obiectul de la care mosteneste informatii (proprietati sau metode)',
    'JSON (JavaScript Object Notation) este un format bazat pe sintaxa obiectului literal din JavaScript cu anumite restrictii, care permite stocarea de date intr-un format text, usor interpretabil de catre JS.',
    'Diferenta dintr-o metoda mutating si o metoda non-mutating este ca cea mutating altereaza setul de date pe care a fost rulata iar cea non-mutating ne da un shalow copy, rupand astfel referinta cu setul de date original.',
    'Diferenta dintre operatorul == si === este ca == verifica valoarea (facand type coercion daca este necesar) iar === verifica si tipul de date al operanzi.',
    'Un arrow function este o functie care nu se defineste prin function keyword; iar "this" in interiorul sau este o referinta spre contextul imediat de deasupra.',
    'Function functions sunt "hoisted" ceea ce inseamna ca ele vor fi valabile peste tot in contextul in care au fost definite, inclusiv daca sunt chemate deasupra liniei la care au fost definite.',
    'ES6 Classes este "sintatic sugar" peste o tehnica de JavaScript prin care se poate simula programarea orientata pe obiecte asa cum este ea intalnita in limbaje precum C++ sau Java sau C#.',
    'Callbackul este o functie care este executata atunci cand in executia programului are loc o anumita actiune sau eveniment.',
    'Event Delegation este o tehnica prin care un event listener (handler) este atasat pe un element parinte sau stramos al altor elemente de pe care vrem sa preluam si ascultam evenimente.',
    'Minificarea este un proces prin care codul (CSS, HTML, JS) este pregatit pentru a fi servit de pe un server web, prin eliminarea spatiilor, punctuatiei redundante sau redenumirea anumitor variabile pentru a avea nume mai scurte.',
    'In sintaxa JavaScript de ES6+ constructorul este metoda care este apelata atunci cand se instantiaza un obiect din acea clasa.',
    'Metoda event.preventDefault poate fi folosita pentru a preveni comportamentul implicit al unui element. Spre exemplu poate preveni formularele de a trimite informatii prin metoda implicita a browserului sau poate preveni navigarea la click pe ancora.',
    'Operatorul spread (...) poate fi folosit pentru a copia (shallow) elementele unui obiect (implicit unui array).',
    'Tipurile de date din JavaScript sunt Number, String, Boolean, Object (Array - e tot obiect), null, undefined, Symbol',
    'Metoda Array.push() poate fi folosita pentru a adauga un element la finalul unui array; este o operatiune mutating care altereaza arrayul in sine si nu creeaza unul nou.',
    'Keywordul continue (valabil in bucle - for, while, do while) va obliga bucla sa sara la urmatoarea iteratie.',
    'Keywordul break (valabile in bucle -for, while, do while si declaratia switch) va opri complet bucla si va continua executia programului, sau va opri cascada din switch.',
  ],
};

export const hintsReducer = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
