import React from "react";
import { connect } from "react-redux";
import store from "../..";
import { pixelTabToggle } from "../../actions";

const PixelTrigger = ({tests: {expanded, stats}}) => {
  return (
    <div className="pixeltab__bottom position-absolute bg-transparent mx-0 mb-1 d-flex justify-content-center">
      <button
        type="button"
        className={`btn bg-light-green pixeltab__badge ${expanded ? "collapsed" : ""}`}
        title="Cerinte corecte"
      >
        👍 <span className="badge badge-light">{stats.passes}</span>
        <span className="sr-only">unread messages</span>
      </button>

      <div
        className="pixeltrigger bg-light row mx-3"
        onClick={() => {
          store.dispatch(pixelTabToggle());
        }}
      >
        <span className="col-6 p-0"></span>
        <span className="col-6 bg-dark p-0"></span>
        <span className="col-6 bg-dark p-0"></span>
        <span className="col-6 p-0"></span>
      </div>

      <button
        type="button"
        className={`btn bg-light-pink pixeltab__badge ${expanded ? "collapsed" : ""}`}
        title="Cerinte gresite"
      >
        👎 <span className="badge badge-light">{stats.failures}</span>
        <span className="sr-only">unread messages</span>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tests: state.tests,
  };
};

export default connect(mapStateToProps)(PixelTrigger);
