import React from 'react';
import { useSelector } from 'react-redux';

const prefixesMap = {
  html01: ['html1'],
  html02: ['html1', 'html2'],
  js01: ['html1', 'html2', 'js'],
  js02: ['html1', 'html2', 'js'],
};

export const HintsDisplay = () => {
  const hint = useSelector(({ dom, hints }) => {
    const { testSuiteId } = dom;
    const modulePrefix = testSuiteId.split('-')[0];

    const array = prefixesMap[modulePrefix].reduce((array, modulePrefix) => {
      return array.concat(hints[modulePrefix]);
    }, []);

    return array[Math.floor(Math.random() * array.length)];
  });

  return (
    <div className="text-center text-secondary">
      <h6 className="w-75 py-1 mx-auto">{hint}</h6>
    </div>
  );
};
