import {
  PIXELTAB_COMMUNICATE_SUITE_ID,
  PIXELTAB_SUITE_END,
  PIXELTAB_TEST_DONE,
  PIXELTAB_TEST_FAILED,
  PIXELTAB_TEST_PASSED,
  PIXELTAB_TEST_FILTER,
  PIXELTAB_SET_TEST_MESSAGE,
  PIXELTAB_SUITE_BEGIN,
  PIXELTAB_SUITE_CACHE_ADD,
  PIXELTAB_TOGGLE,
  PIXELTAB_COLLAPSE,
  PIXELTAB_EXPAND,
} from './types';

export const communicateTestSuiteId = (id) => {
  return {
    type: PIXELTAB_COMMUNICATE_SUITE_ID,
    payload: id,
  };
};

export const pixelTabTestFailed = (failedTest, error) => {
  return {
    type: PIXELTAB_TEST_FAILED,
    payload: {
      failedTest,
      error,
    },
  };
};

export const pixelTabTestPassed = (passedTest) => {
  return {
    type: PIXELTAB_TEST_PASSED,
    payload: passedTest,
  };
};

export const pixelTabTestDone = (test) => {
  return {
    type: PIXELTAB_TEST_DONE,
    payload: test,
  };
};

export const pixelTabTestFilter = (filter) => {
  return {
    type: PIXELTAB_TEST_FILTER,
    payload: filter,
  };
};

export const pixelTabSuiteBegin = () => {
  return {
    type: PIXELTAB_SUITE_BEGIN,
  };
};

export const pixelTabSuiteEnd = (mochaReporterStats) => {
  return {
    type: PIXELTAB_SUITE_END,
    payload: mochaReporterStats,
  };
};

export const pixelTabSetTestMessage = (testId, testMessage) => {
  return {
    type: PIXELTAB_SET_TEST_MESSAGE,
    testId,
    payload: testMessage,
  };
};

export const pixelTabAddTestSuiteId = (message) => {
  return {
    type: PIXELTAB_SUITE_CACHE_ADD,
    payload: message,
  };
};

export const pixelTabToggle = () => {
  return {
    type: PIXELTAB_TOGGLE,
  };
};

export const pixelTabCollapse = () => {
  return {
    type: PIXELTAB_COLLAPSE,
  };
};

export const pixelTabExpand = () => {
  return {
    type: PIXELTAB_EXPAND,
  };
};
