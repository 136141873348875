import React from 'react';
import PixelTab from './pixelTab/pixelTab';
import PixelTrigger from './pixelTab/pixelTrigger';

const App = () => {
  return (
    <div className="pixeltab-frame">
      <PixelTab></PixelTab>
      <PixelTrigger></PixelTrigger>
    </div>
  );
};

export default App;
