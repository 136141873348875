import { combineReducers } from 'redux';
import { hintsReducer } from './hintsReducer';
import {
  domReducer,
  mochaReporterReducer,
  suiteCacheReducer,
} from './pixelTabReducer';

export default combineReducers({
  dom: domReducer,
  tests: mochaReporterReducer,
  testSuiteCache: suiteCacheReducer,
  hints: hintsReducer,
});
