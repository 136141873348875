import React from 'react';
import { TEST_STATE_FAILED } from '../../shared/reporters/pixelTab';

const TestResult = (props) => {
  const test = props.test;
  const fullTitle = `Cerinta este: ${test.customTitle}`;
  if (test.state === TEST_STATE_FAILED) {
    return (
      <div className="bg-light-pink my-2 ">
        <div className="p-2 d-flex">
          <span className="pr-2">👎</span>
          <span>{fullTitle}</span>
        </div>
        <div className="bg-light-gray pl-2-3 pr-2 py-1">
          {
            props.test.messages[
              Math.floor(Math.random() * props.test.messages.length)
            ]
          }
        </div>
      </div>
    );
  }

  return <div className="bg-light-green my-2 p-2 d-flex">
          <span className="pr-2">👍</span>
          <span>{fullTitle}</span>
        </div>;
};

export default TestResult;
