import {
  PIXELTAB_COMMUNICATE_SUITE_ID,
  PIXELTAB_SUITE_END,
  PIXELTAB_TEST_DONE,
  PIXELTAB_TEST_FAILED,
  PIXELTAB_TEST_PASSED,
  PIXELTAB_TEST_FILTER,
  PIXELTAB_SET_TEST_MESSAGE,
  PIXELTAB_SUITE_BEGIN,
  PIXELTAB_SUITE_CACHE_ADD,
  PIXELTAB_TOGGLE,
  PIXELTAB_COLLAPSE,
  PIXELTAB_EXPAND,
} from '../actions/pixelTab/types';

const INITIAL_DOM_STATE = {
  testSuiteId: '',
};
export const TESTS_RUNNING = 'running';
export const TESTS_DONE = 'done';

const INITIAL_TESTS_STATE = {
  stats: {},
  passed: [],
  failed: [],
  all: [],
  // I can start with running, here but if absolutely necessary, this can be changed by another
  // mocha reporter event
  status: TESTS_RUNNING,
  suiteMessages: {},
  expanded:
    !localStorage.getItem('tab-expanded') ||
    localStorage.getItem('tab-expanded') === 'true',
  filterBy: localStorage.getItem('stats-filter'),
};

const INITIAL_SUITE_CACHE_STATE = {
  testSuiteIds: [],
};

export const domReducer = (state = INITIAL_DOM_STATE, action) => {
  if (action.type === PIXELTAB_COMMUNICATE_SUITE_ID) {
    return {
      ...state,
      testSuiteId: action.payload,
    };
  }

  return state;
};

export const mochaReporterReducer = (state = INITIAL_TESTS_STATE, action) => {
  switch (action.type) {
    case PIXELTAB_SUITE_BEGIN:
      // reset all state but keep the actual
      // pixeltab open, and also keep the filters
      // everything else can just be reset via de literal above
      return {
        ...INITIAL_TESTS_STATE,
        expanded: state.expanded,
        filterBy: state.filterBy,
      };
    case PIXELTAB_SUITE_END:
      return {
        ...state,
        stats: action.payload,
        status: TESTS_DONE,
      };
    case PIXELTAB_TEST_PASSED:
      return {
        ...state,
        passed: [...state.passed, action.payload],
      };
    case PIXELTAB_TEST_FAILED:
      return {
        ...state,
        failed: [...state.all, action.payload],
      };
    case PIXELTAB_TEST_DONE:
      return {
        ...state,
        all: [...state.all, action.payload],
        filtered: state.all.filter(
          (t) =>
            !localStorage.getItem('stats-filter') ||
            t.state === localStorage.getItem('stats-filter'),
        ),
      };
    case PIXELTAB_TEST_FILTER:
      localStorage.setItem('stats-filter', action.payload);
      return {
        ...state,
        filterBy: action.payload,
      };
    case PIXELTAB_SET_TEST_MESSAGE:
      return {
        ...state,
        suiteMessages: {
          ...state.suiteMessages,
          [action.testId]:
            state.suiteMessages[action.testId] === undefined
              ? [action.payload]
              : [...state.suiteMessages[action.testId], action.payload],
        },
      };
    case PIXELTAB_TOGGLE:
      localStorage.setItem('tab-expanded', !state.expanded);
      return {
        ...state,
        expanded: !state.expanded,
      };
    case PIXELTAB_COLLAPSE:
      localStorage.setItem('tab-expanded', false);
      return {
        ...state,
        expanded: false,
      };
    case PIXELTAB_EXPAND:
      localStorage.setItem('tab-expanded', true);
      return {
        ...state,
        expanded: true,
      };
    default:
      return state;
  }
};

export const suiteCacheReducer = (
  state = INITIAL_SUITE_CACHE_STATE,
  action,
) => {
  switch (action.type) {
    case PIXELTAB_SUITE_CACHE_ADD:
      return {
        ...state,
        testSuiteIds: [...state.testSuiteIds, action.payload],
      };
    default:
      return state;
  }
};
