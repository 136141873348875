import { mocha } from 'mocha';

import {
  pixelTabSuiteEnd,
  pixelTabTestDone,
  pixelTabTestFailed,
  pixelTabTestPassed,
  pixelTabSuiteBegin,
} from './../../actions/pixelTab/pixelTab';
import store from './../../index';

const { EVENT_RUN_END, EVENT_TEST_FAIL, EVENT_TEST_PASS, EVENT_SUITE_BEGIN } =
  mocha.Mocha.Runner.constants;

export const TEST_STATE_FAILED = 'failed';
export const TEST_STATE_PASSED = 'passed';

export default class PixelTabReporter {
  constructor(runner) {
    const stats = runner.stats;

    runner
      .once(EVENT_SUITE_BEGIN, () => {
        store.dispatch(pixelTabSuiteBegin());
      })
      .on(EVENT_TEST_PASS, (test) => {
        // Test#fullTitle() returns the suite name(s)
        // prepended to the test title
        store.dispatch(pixelTabTestPassed(test));
        store.dispatch(pixelTabTestDone(test));
        test.customTitle = test.fullTitle();
      })
      .on(EVENT_TEST_FAIL, (test, err) => {
        test.expected = err.expected;
        test.actual = err.actual;
        test.messages = this.setCustomMessages(test) || [];
        // idealy don't send functions via the store...
        test.customTitle = test.fullTitle();

        store.dispatch(pixelTabTestFailed(test, err));
        store.dispatch(pixelTabTestDone(test));
      })
      .once(EVENT_RUN_END, () => {
        store.dispatch(pixelTabSuiteEnd(stats));
      });
  }

  setCustomMessages(test) {
    const state = store.getState();
    const testId = test.ctx.testId;
    if (testId === undefined) {
      throw new Error(`Nu ai adaugat un id de test pentru ${test.fullTitle()}`);
    }

    const messagesArray = state.tests.suiteMessages[testId] || [];
    let customMessages = [];

    if (messagesArray.length > 0) {
      customMessages = state.tests.suiteMessages[testId];
    } else {
      // perhaps if these messages are strings I can also add this as a message in the array
      customMessages = [
        'Am intampinat o eroare! Te rog anunta pe Google Classroom.',
      ];
    }

    return customMessages;
  }
}
