import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  communicateTestSuiteId,
  pixelTabExpand,
  pixelTabToggle,
  pixelTabTestFilter,
} from './actions/pixelTab/pixelTab';

import mocha from 'mocha';

import App from './components/App';
import reducers from './reducers';

const store = createStore(reducers, applyMiddleware(thunk));
const pixelTabHost = document.querySelector('.pixeltab-host');
const testSuiteId = pixelTabHost.id;

// store.dispatch(communicateTestSuiteId(testSuiteId));
store.dispatch(communicateTestSuiteId(testSuiteId));

document.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    store.dispatch(pixelTabToggle());
  }
});

const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
const address = `${protocol}${window.location.host}${window.location.pathname}/ws`;
const socket = new WebSocket(address);

socket.onmessage = (msg) => {
  if (msg.data === 'refreshcss') {
    setTimeout(() => {
      mocha.run();
    }, 500);
  }
};

if (localStorage.getItem('tab-expanded') === null) {
  store.dispatch(pixelTabExpand());
}

if (localStorage.getItem('stats-filter') === null) {
  store.dispatch(pixelTabTestFilter(''));
}

ReactDOM.render(
  <Provider store={store}>
    <App></App>
  </Provider>,
  pixelTabHost,
);

export default store;
