export const PIXELTAB_COMMUNICATE_SUITE_ID = 'PIXELTAB_COMMUNICATE_SUITE_ID';

export const PIXELTAB_SUITE_BEGIN = 'PIXELTAB_SUITE_BEGIN';
export const PIXELTAB_SUITE_END = 'PIXELTAB_SUITE_END';
export const PIXELTAB_TEST_PASSED = 'PIXELTAB_TEST_PASSED';
export const PIXELTAB_TEST_FAILED = 'PIXELTAB_TEST_FAILED';
export const PIXELTAB_TEST_DONE = 'PIXELTAB_TEST_DONE';
export const PIXELTAB_TEST_FILTER = 'PIXELTAB_TEST_FILTER';
export const PIXELTAB_SET_TEST_MESSAGE = 'PIXELTAB_SET_TEST_MESSAGE';
// there shall be a need to unset these messages as well

export const PIXELTAB_SUITE_CACHE_ADD = 'PIXELTAB_SUITE_CACHE_ADD';
export const PIXELTAB_SUITE_CACHE_CLEAR = 'PIXELTAB_SUITE_CACHE_CLEAR';

export const PIXELTAB_TOGGLE = 'PIXELTAB_TOGGLE';
export const PIXELTAB_COLLAPSE = 'PIXELTAB_COLLAPSE';
export const PIXELTAB_EXPAND = 'PIXELTAB_EXPAND';
